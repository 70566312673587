import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link, navigate } from "gatsby-plugin-intl"
import ReactPlayer from "react-player"

const BlackFridayHomeVideoBannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  color: #262626;
  padding: 0;
`

const ButtonTitle = styled.span`
  display: none;
`

const LeftSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  background: linear-gradient(
    88.29deg,
    rgba(39, 245, 150, 0.5) 1.11%,
    rgba(63, 226, 173, 0.5) 19.4%,
    rgba(25, 195, 222, 0.5) 69.83%
  );
  background: #111112;

  @media (max-width: 600px) {
    display: none;
  }
`

const BlackFridayTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #fff;
  max-width: 600px;
  padding: 0px 30px;
  z-index: 1;

  @media (max-width: 992px) {
    padding: 0px 20px;
  }
`

const BlackFridayLogo = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;
`

const BlackFridayLastDaysText = styled.div`
  font-size: 64px;
  line-height: 1;
  color: #262626;
  font-family: "Museo";
  font-weight: bold;
  margin-bottom: 10px;
  background: linear-gradient(
    61.74deg,
    #ff7881 -4.05%,
    #ffab61 52.44%,
    #ffe775 91.92%
  );
  border-radius: 17px 0px;
  padding: 10px 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
    font-size: 26px;

    @media (max-width: 767px) {
      font-size: 21px;
    }
  }

  @media (max-width: 850px) {
    font-size: 64px;
  }

  @media (max-width: 767px) {
    font-size: 48px;
  }
`

const BlackFridayText = styled.div`
  font-size: 30px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  letter-spacing: 1px;

  > span:nth-of-type(1) {
    font-size: 80px;
    font-family: "BebasNeueBold";
    font-weight: 700;
    line-height: 1.1;
    margin-right: 10px;
    color: #fff;
    text-shadow: 0.17px 0px 2.71px #f9a326;
    filter: drop-shadow(0px 0.35px 1.78px #f9a326);

    @media (max-width: 992px) {
      font-size: 60px;
    }

    @media (max-width: 767px) {
      font-size: 52px;
      margin-right: 7px;
    }
  }

  span:nth-of-type(2) {
    color: #fddeb0;

    span {
      display: block;
      fonr-weight: bold;
      color: #fff;
      text-shadow: 0.5px 0px 4px 0px #f9a326;
      filter: drop-shadow(0px 1.74px 8.7px #f9a326);
    }
  }

  @media (max-width: 992px) {
    font-size: 24px;
  }

  @media (max-width: 767px) {
    font-size: 18px;
  }
`

const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #262626;
  padding: 0;
`

const AbsoluteButton = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 2;
`

const DesktopVideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  div {
    position: relative;
  }

  iframe {
    width: 300% !important;
    height: 300% !important;
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0px;

    @media (max-width: 4800px) {
      width: 250% !important;
      height: 250% !important;
    }

    @media (max-width: 3200px) {
      width: 200% !important;
      height: 200% !important;
    }

    @media (max-width: 2100px) {
      width: 150% !important;
      height: 150% !important;
    }

    @media (max-width: 1920px) {
      width: 130% !important;
      height: 130% !important;
    }

    @media (max-width: 1400px) {
      width: 150% !important;
      height: 150% !important;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileVideoWrapper = styled.div`
  width: 100%;
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  iframe {
    width: 140% !important;
    height: 140% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px;
    border: 0px;

    @media (max-width: 480px) {
      width: 130% !important;
      height: 120% !important;
    }

    @media (max-width: 420px) {
      width: 125% !important;
      height: 100% !important;
    }

    @media (max-width: 350px) {
      width: 130% !important;
      height: 100% !important;
    }
  }

  @media (max-width: 600px) {
    display: block;
  }
`

const TopSection = styled.div`
  display: flex;
  background: #e5e5e5;
  width: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #fff;
  min-height: 800px;

  @media (max-width: 6200px) {
    min-height: 700px;
  }

  @media (max-width: 3800px) {
    min-height: 600px;
  }

  @media (max-width: 2400px) {
    min-height: 510px;
  }

  @media (max-width: 992px) {
    min-height: 450px;
  }

  @media (max-width: 600px) {
    min-height: 400px;
  }

  :hover {
    color: #fff;
  }
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  max-width: 1200px;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  padding: 0px 20px;
  z-index: 1;

  @media (max-width: 600px) {
    display: none;
  }
`

const Title = styled.div`
  font-size: 60px;
  line-height: 1.2;
  color: #fff;
  font-family: "Museo";
  font-weight: 400;
  text-align: center;

  span {
    display: block;
    font-weight: bold;
  }

  @media (max-width: 850px) {
    font-size: 48px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileTitle = styled.div`
  font-size: 32px;
  line-height: 1.1;
  color: #fff;
  font-family: "Museo";
  font-weight: 400;
  text-align: center;
  display: none;

  span {
    display: block;
    font-weight: bold;
  }

  @media (max-width: 600px) {
    display: block;
  }

  @media (max-width: 350px) {
    font-size: 28px;
  }
`

const Button = styled(Link)`
  background: #262626;
  background: linear-gradient(
    61.74deg,
    #ff7881 -4.05%,
    #ffab61 52.44%,
    #ffe775 91.92%
  );
  border-radius: 6px;
  padding: 15px 20px;
  color: #fff;
  color: #000;
  font-family: "Museo";
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  min-width: 160px;
  text-align: center;
  margin-top: 35px;
  border-radius: 25px 0px;
  position: relative;

  &:before {
    // content: "";
    position: absolute;
    top: 5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: linear-gradient(
      61.74deg,
      #ff7881 -4.05%,
      #ffab61 52.44%,
      #ffe775 91.92%
    );
    border-radius: 25px 0;
    z-index: -1;
  }

  :hover {
    color: #fff;
  }

  @media (max-width: 992px) {
    margin-top: 25px;
  }

  @media (max-width: 600px) {
    display: none;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  top: 30px;
  padding: 0px 30px;
  z-index: 1;

  @media (max-width: 1600px) {
    padding: 0px 20px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  bottom: 30px;
  padding: 0px 30px;
  z-index: 1;

  @media (max-width: 1600px) {
    padding: 0px 20px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 600px) {
    justify-content: center;
  }
`

const MedicaleImage = styled.img`
  max-width: 65px;
  height: 100%;
  max-height: 65px;
  width: 100%;
  margin: 0px 15px 0px 0px;

  @media (max-width: 600px) {
    max-width: 50px;
    max-height: 50px;
  }
`

const InnovationImage = styled.img`
  max-width: 65px;
  max-height: 65px;
  height: 100%;
  width: 100%;
  margin: 0px;

  @media (max-width: 600px) {
    max-width: 50px;
    max-height: 50px;
  }
`

const MobileButton = styled(Link)`
  background: #262626;
  border-radius: 6px;
  padding: 15px 20px;
  color: #fff;
  font-family: "Museo";
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  min-width: 220px;
  text-align: center;
  margin-top: 10px;
  display: none;
  border-radius: 25px 0px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: linear-gradient(
      61.74deg,
      #ff7881 -4.05%,
      #ffab61 52.44%,
      #ffe775 91.92%
    );
    border-radius: 25px 0;
    z-index: -1;
  }

  :hover {
    color: #fff;
  }

  @media (max-width: 550px) {
    display: block;
    text-align: center;
    max-width: 150px;
    min-width: 150px;
    margin: 10px auto 0px;
  }
`

export const BlackFridayHomeVideoBanner = ({ intl, data }) => {
  return (
    data && (
      <BlackFridayHomeVideoBannerWrapper>
        <LeftSection>
          {data.video_section_text.button_text &&
            data.video_section_text.button_url && (
              <AbsoluteButton to="/shop/lyneup-test/">
                <ButtonTitle>{data.video_section_text.button_text}</ButtonTitle>
              </AbsoluteButton>
            )}
          <BlackFridayTextWrapper>
            <BlackFridayLogo
              className="lazyload"
              data-src="https://static.percko.com/uploads/1a8adc35-2d37-4b28-9332-99fa4ea7fb0f.gif"
            />
            {/* <BlackFridayLastDaysText
              dangerouslySetInnerHTML={{
                __html: "Sale <span>Last<br/>Days</span>",
              }}
            /> */}
            <BlackFridayText
              dangerouslySetInnerHTML={{
                __html:
                  "<span>£15 OFF</span><span><span>one</span> PERCKO top</span>",
              }}
            />
            <BlackFridayText
              dangerouslySetInnerHTML={{
                __html:
                  "<span>£50 OFF</span><span><span>two</span> PERCKO tops</span>",
              }}
            />
            {data.video_section_text.button_text &&
              data.video_section_text.button_url && (
                <Button to="/shop/lyneup-test/">{"Shop now >"}</Button>
              )}
          </BlackFridayTextWrapper>
        </LeftSection>
        <RightSection>
          <TopSection className="HomeVideoBanner">
            {data.video_section_text.button_text &&
              data.video_section_text.button_url && (
                <AbsoluteButton to={data.video_section_text.button_url}>
                  {data.video_section_text.button_text}
                </AbsoluteButton>
              )}
            <DesktopVideoWrapper>
              <ReactPlayer
                url="https://player.vimeo.com/video/879759090?h=7b04564a05&background=1&title=0&byline=0&portrait=0&playsinline=0&autopause=0"
                playing={true}
                controls={false}
                muted={true}
                loop={true}
                width="100%"
                height="100%"
                playsinline={true}
                playsInline
              />
            </DesktopVideoWrapper>
            <MobileVideoWrapper>
              <ReactPlayer
                url="https://player.vimeo.com/video/721016652?h=3afc91d546&background=1&title=0&byline=0&portrait=0&playsinline=0&autopause=0"
                playing={true}
                controls={false}
                muted={true}
                loop={true}
                width="100%"
                height="100%"
                playsinline={true}
                playsInline
              />
            </MobileVideoWrapper>
            <TextWrapper lang={intl.locale}>
              {/* {data.video_section_text.title && (
                <Title
                  dangerouslySetInnerHTML={{
                    __html: data.video_section_text.title,
                  }}
                />
              )} */}
              {data.video_section_text.mobile_title && (
                <MobileTitle
                  dangerouslySetInnerHTML={{
                    __html: data.video_section_text.mobile_title,
                  }}
                />
              )}
            </TextWrapper>
            <BottomWrapper>
              {data.video_section_text.medical_image &&
                data.video_section_text.innovation_image && (
                  <IconWrapper>
                    <MedicaleImage
                      className="lazyload"
                      data-src={data.video_section_text.medical_image}
                    />
                    <InnovationImage
                      className="lazyload"
                      data-src={data.video_section_text.innovation_image}
                    />
                  </IconWrapper>
                )}
              {/* {data.video_section_text.button_text &&
                data.video_section_text.button_url && (
                  <MobileButton to={data.video_section_text.button_url}>
                    {"Shop now >"}
                  </MobileButton>
                )} */}
            </BottomWrapper>
          </TopSection>
        </RightSection>
      </BlackFridayHomeVideoBannerWrapper>
    )
  )
}

export default injectIntl(BlackFridayHomeVideoBanner)
